import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerQuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerQuote = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 20px 0 20px 0;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);

  @media screen and (max-width: 575px) {
    padding: 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(9, 21, 79, 0) 13.9%,
    rgba(12, 16, 103, 0.86) 35.34%,
    rgba(13, 14, 115, 0.98) 68.71%,
    #1402ac 144.52%
  );
  z-index: 2;

  @media (min-width: 992px) {
    max-width: 75%;
  }

  @media screen and (max-width: 575px) {
    background: linear-gradient(
      90deg,
      rgba(12, 16, 103, 0.65) 13.9%,
      rgba(12, 16, 103, 0.86) 35.34%,
      rgba(13, 14, 115, 0.98) 68.71%,
      #1402ac 144.52%
    );
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 60%;
  height: 100%;
  max-width: 1300px;
  top: 0;
  right: 40%;
  z-index: 1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};

  & h2 {
    padding-bottom: 20px;

    @media screen and (min-width: 992px) {
      font-size: 75px;
      line-height: 70px;
    }
  }

  & p {
    font-size: 25px;
    line-height: 45px;
  }

  @media screen and (max-width: 991px) {
    & p {
      font-size: 18px;
      line-height: 26px;
    }
  }
`

const Block = styled.div`
  position: relative;
  z-index: 3;
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 45px;
  padding: 40px;
  max-width: 540px;

  @media screen and (max-width: 350px) {
    padding: 20px 15px;
  }
`

const BannerQuote: React.FC<BannerQuoteProps> = ({ fields }) => (
  <StyledBannerQuote>
    <BannerWrapper>
      <Gradient />
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div className="container py-3">
        <div className="d-flex justify-content-end">
          <Block>
            <Content content={fields.description || ''} />
          </Block>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerQuote>
)

export default BannerQuote
