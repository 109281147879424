import React from 'react'

// Images
import Linkedin from 'img/linkedin.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Facebook from 'img/facebook.inline.svg'
import Mail from 'img/mail.inline.svg'
import Whatsapp from 'img/whatsapp.inline.svg'

// Third Party
import styled from 'styled-components'

interface ShareProps {
  location: any
  title: string
}

const StyledShare = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & a,
  button {
    padding: 0;
    margin: 6px 0;
  }

  & p {
    font-family: ${({ theme }) => theme.font.family.secondary};
    transform: rotate(270deg);
    margin: 10px 0 10px 0;
    font-size: 16px;
    line-height: 35px;
    color: ${({ theme }) => theme.color.text.dark};
    font-weight: ${({ theme }) => theme.font.weight.light};
  }

  @media screen and (max-width: 991px) {
    flex-direction: row-reverse;

    & a,
    button {
      padding: 0;
      margin: 0 6px;
    }

    & p {
      transform: rotate(0deg);
      margin: 10px;
    }
  }

  @media screen and (max-width: 350px) {
    & a,
    button {
      padding: 0;
      margin: 0 3px;
    }

    & p {
      transform: rotate(0deg);
      margin: 10px 5px 10px 0;
    }
  }
`

const stripHtml = (html: string) => {
  if (typeof document !== 'undefined') {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
  }

  return ''
}

const Share: React.FC<ShareProps> = ({ location, title }) => (
  <StyledShare>
    <ShareButtonAbstract
      href={`https://www.linkedin.com/shareArticle?mini=true&url=${
        location.href
      }&title=${stripHtml(title)}&summary=Mooi%20artikel!&amp;source=${
        location.href
      }`}
      platform="linkedin"
    >
      <Linkedin />
    </ShareButtonAbstract>

    <ShareButtonAbstract
      href={`https://twitter.com/intent/tweet/?text=${stripHtml(title)}&url=${
        location.href
      }`}
      platform="twitter"
    >
      <Twitter />
    </ShareButtonAbstract>

    <ShareButtonAbstract
      href={`https://www.facebook.com/sharer/sharer.php?u=${location.href}`}
      platform="facebook"
    >
      <Facebook />
    </ShareButtonAbstract>
    <a
      href={`mailto:%20?SUBJECT=${stripHtml(title)}&amp;BODY=${location.href}`}
      data-platform="email"
    >
      <Mail />
    </a>
    <a
      href={`whatsapp://send?text=${location.href}`}
      data-action="share/whatsapp/share"
      data-platform="whatsapp"
    >
      <Whatsapp />
    </a>
    <p>Share</p>
  </StyledShare>
)

export default Share

interface ShareButtonAbstractProps {
  className?: string
  platform: string
  href: string
  children: any
}

const ShareButtonAbstract: React.FC<ShareButtonAbstractProps> = ({
  className = '',
  platform,
  href,
  children,
}) => {
  const open = () => {
    if (typeof window !== 'undefined') {
      window.open(
        href,
        'targetWindow',
        `toolbar=no,
      location=no,
      status=no,
      menubar=no,
      scrollbars=yes,
      resizable=yes,
      width=500,
      height=500`
      )
    }
  }

  return (
    <button
      type="button"
      onClick={open}
      className={className}
      data-platform={platform}
    >
      {children}
    </button>
  )
}
