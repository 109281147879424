/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Images
import Check from 'img/check.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Button from 'components/elements/Buttons/Button'

interface BannerBenefitsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerBenefits = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 20px 0 30px 0;

  @media screen and (max-width: 991px) {
    padding: 15px 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.secondary};

  z-index: 1;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  & h2 {
    @media screen and (min-width: 1024px) {
      font-size: 75px;
      line-height: 70px;
    }

    @media screen and (min-width: 992px) {
      font-size: 60px;
      line-height: 55px;
    }

    @media screen and (max-width: 991px) {
      font-size: 50px;
      line-height: 40px;
    }
  }
`

const Benefits = styled.div`
  position: relative;
  z-index: 3;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.bold};

    @media screen and (min-width: 992px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media screen and (max-width: 991px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 3;
`

const SvgWrapper = styled.div``

const BannerBenefits: React.FC<BannerBenefitsProps> = ({ fields }) => (
  <StyledBannerBenefits>
    <BannerWrapper>
      <Gradient />
      <div className="container py-lg-5">
        <div className="row">
          <div className="col-lg-4 pb-lg-0 pb-3">
            <Content content={fields.description || ''} />
          </div>
          <div className="col-lg-8 py-4">
            <Benefits>
              {fields.benefits?.map((b, index) => (
                <div className="pb-4 d-flex align-items-start" key={index}>
                  <SvgWrapper className="me-3">
                    <Check />
                  </SvgWrapper>
                  <div className="pt-lg-1 pt-2 w-100">
                    <ParseContent content={b?.text || ''} />
                  </div>
                </div>
              ))}
            </Benefits>
          </div>
        </div>
        <ButtonWrapper className="pt-lg-2 d-flex justify-content-center">
          <Button
            arrow
            className="filled primaryLight hover-light"
            to={fields.link?.url || '/'}
          >
            {fields.link?.title}
          </Button>
        </ButtonWrapper>
      </div>
    </BannerWrapper>
  </StyledBannerBenefits>
)

export default BannerBenefits
