/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Buttons/Button'
import { getLanguage } from 'services/language'

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 100px 0;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);

  @media screen and (max-width: 991px) {
    padding: 120px 0 40px 0;
  }

  @media screen and (max-width: 575px) {
    padding: 120px 0 0 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 70%;
  background: linear-gradient(
    264.17deg,
    rgba(9, 21, 79, 0) 13.9%,
    rgba(12, 16, 103, 0.86) 25.34%,
    rgba(13, 14, 115, 0.98) 38.71%,
    #1402ac 144.52%
  );
  z-index: 2;
`

const StyledPlaatjie = styled(Plaatjie) <{ image: any }>`
  position: absolute !important;
  width: 60%;
  height: 100%;
  max-width: 1300px;
  top: 0;
  left: 40%;
  z-index: 1;

  @media screen and (max-width: 350px) {
    width: 80%;
  }
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  & h1 {
    @media screen and (min-width: 992px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media screen and (max-width: 991px) {
      font-size: 40px;
      line-height: 50px;
    }

    @media screen and (max-width: 575px) {
      font-size: 30px;
      line-height: 40px;
    }

    @media screen and (max-width: 350px) {
      font-size: 25px;
      line-height: 30px;
    }
  }
`

const ButtonWrapper = styled.div`
  position: relative;
  z-index: 3;
`

const Or = styled.div`
  color: ${({ theme }) => theme.color.text.light};
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media screen and (max-width: 575px) {
    text-align: center;
    width: 100%;
    justify-content: center;
    padding: 10px 0;
  }
`

const StyledButton = styled(Button)`
  font-size: 16px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const language = getLanguage()

  return (
    <StyledBannerHome>
      <BannerWrapper>
        <Gradient />
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <div className="container py-lg-5 py-3">
          <Content content={fields.description || ''} className="pt-5 pt-lg-5" />
          <ButtonWrapper className="pt-5 d-flex flex-wrap">
            {fields.buttons?.map((b, index) => (
              <>
                <StyledButton
                  to={b?.link?.url}
                  className={
                    index === 0
                      ? `filled secondary hover-secondary`
                      : `secondary hover-primaryLight`
                  }
                  key={index}
                  target={b?.link?.target || '_self'}
                >
                  {b?.link?.title}
                </StyledButton>
                {index === 0 && (
                  <Or className="mx-4 d-flex align-items-center">
                    {language === 'nl_NL' ? `of` : `or`}
                  </Or>
                )}
              </>
            ))}
          </ButtonWrapper>
        </div>
      </BannerWrapper>
    </StyledBannerHome>
  )
}

export default BannerHome
