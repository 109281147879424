import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Share from 'components/elements/Share'

interface BannerDetailProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
}

const StyledBannerDetail = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 200px 0 0px 0;

  @media screen and (max-width: 991px) {
    padding: 100px 0 0 0;
    margin: 0 0 80px 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);

  z-index: 1;
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0.2;
  mix-blend-mode: lighten;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;
`

const DetailImageWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  position: absolute;
  z-index: 3;
  border-radius: 40px;
  overflow: hidden;
  width: 90%;
  top: -80px;
  left: 5%;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const ShareWrapper = styled.div`
  position: absolute;
  top: 350px;
  right: 25px;

  @media screen and (max-width: 991px) {
    position: absolute;
    top: unset;
    right: unset;
    left: 35px;
    bottom: -65px;
  }

  @media screen and (max-width: 575px) {
    left: 0;
  }
`

const DetailWrapper = styled.div`
  @media screen and (min-width: 992px) {
    position: relative;
  }
`

const DetailImage = styled(Plaatjie)``

const BannerDetail: React.FC<BannerDetailProps> = ({ fields, location }) => (
  <StyledBannerDetail>
    <BannerWrapper>
      <Gradient />
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div className="container py-lg-4 py-3">
        <div className="row">
          <DetailWrapper className="col-lg-4">
            <DetailImageWrapper>
              <DetailImage image={fields.detailimage} alt="" />
            </DetailImageWrapper>
            <ShareWrapper>
              <Share title={fields.description || ''} location={location} />
            </ShareWrapper>
          </DetailWrapper>
          <div className="col-lg-8">
            <Content content={fields.description || ''} />
          </div>
        </div>
      </div>
    </BannerWrapper>
  </StyledBannerDetail>
)

export default BannerDetail
