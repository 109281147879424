/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

// Images
import Down from 'img/down.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Third Party
import { motion } from 'framer-motion'
import { useMedia } from 'react-use'

interface BannerAboutusProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerAboutus = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 80px 0 20px 0;
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 500px;
  width: 100%;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);
  z-index: 1;

  @media (max-width: 1199px){
    min-height: 800px;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  min-height: 500px;
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0.2;
  mix-blend-mode: lighten;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  @media screen and (min-width: 991px) {
    & h1 {
      padding-bottom: 20px;
    }

    padding: 0 220px;
  }
`

const Block = styled(motion.div)`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-radius: 40px;
  background-color: ${({ theme }) => theme.color.light};
  width: 340px;
  padding: 20px 20px 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  will-change: height;

  & p {
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 14px;
    line-height: 25px;
    padding-bottom: 3rem;
  }

  @media (min-width: 992px) {
    & h2 {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    margin: 0 10px;
  }

  @media screen and (max-width: 575px) {
    margin: 0 0 30px 0;
  }
`

const BlockContainer = styled.div`
  position: relative;
  z-index: 2;
`

const Icon = styled(Plaatjie)`
  width: 160px;
  height: 160px;
  margin-top: 20px;

  & img {
    object-fit: contain !important;
  }

  @media screen and (max-width: 991px) {
    width: 100px;
    height: 80px;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 10px;
  }
`

const SvgWrapper = styled(motion.div)`
  position: absolute;
  bottom: -20px;
  left: calc(50% - 20px);
  cursor: pointer;

  & svg {
    height: 40px;
    width: 40px;
  }
`

const BannerAboutus: React.FC<BannerAboutusProps> = ({ fields }) => {
  const [open, setOpen] = React.useState(-1)
  const mobile = useMedia('(max-width: 575px)')

  return (
    <StyledBannerAboutus>
      <BannerWrapper>
        <Gradient />
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <div className="container py-5">
          <Content content={fields.description || ''} className="pt-5 pt-lg-0" />
          <BlockContainer className="d-flex flex-sm-nowrap flex-wrap pt-5 justify-content-around">
            {fields.usps?.map((b, index) => (
              <Block
                className="position-relative"
                initial={mobile ? { height: 480 } : { height: 'fit-content' }}
                animate={
                  open === index
                    ? { height: 'fit-content' }
                    : mobile
                    ? { height: 'fit-content' }
                    : { height: 480 }
                }
                transition={{
                  type: 'spring',
                  damping: 20,
                }}
                key={index}
              >
                <div>
                  <ParseContent content={b?.title || ''} />
                  <div className="d-flex justify-content-center">
                    <Icon image={b?.icon} alt="" />
                  </div>
                </div>
                <div>
                  {open === index ? (
                    <div className="pt-4">
                      <ParseContent content={b?.story || ''} />
                    </div>
                  ) : (
                    <ParseContent content={b?.description || ''} />
                  )}
                </div>
                <SvgWrapper
                  onClick={() => setOpen(open === index ? -1 : index)}
                  animate={
                    open === index
                      ? { transform: 'rotate(180deg)' }
                      : { transform: 'rotate(0deg)' }
                  }
                  className="mb-5"
                >
                  <Down />
                </SvgWrapper>
              </Block>
            ))}
          </BlockContainer>
        </div>
      </BannerWrapper>
    </StyledBannerAboutus>
  )
}

export default BannerAboutus
