import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  height: fit-content;
  padding: 150px 0 150px 0;

  @media screen and (max-width: 991px) {
    padding: 80px 0 150px 0;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);

  z-index: 1;
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 2;
  opacity: 0.2;
  mix-blend-mode: lighten;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;

  @media screen and (max-width: 575px) {
    & h1 {
      font-size: 30px;
      line-height: 34px;
    }
  }

  @media screen and (max-width: 350px) {
    & h1 {
      font-size: 24px;
      line-height: 30px;
    }
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({ fields }) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <Gradient />
      <StyledPlaatjie alt="banner" image={fields?.image} />
      <div className="container py-5">
        <Content content={fields.description || ''} className="pt-5 pt-lg-0" />
      </div>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
