/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled, { css } from 'styled-components'

// Images
import Pin from 'img/pin.inline.svg'
import Clock from 'img/clock.inline.svg'
import Bars from 'img/bars.inline.svg'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Button from 'components/elements/Buttons/Button'

// Third Party
import useLayout from 'hooks/useLayout'

interface BannerVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const StyledBannerVacancies = styled.section`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  padding-bottom: 200px;

  @media screen and (max-width: 991px) {
    padding-bottom: 20px;
  }
`

const BannerWrapper = styled.div`
  position: relative;
  height: 890px;
  padding: 100px 0 100px 0;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);

  @media screen and (max-width: 991px) {
    padding: 80px 0 30px 0;
    height: fit-content;
  }
`

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(277.87deg, #09154f 26.05%, #1402ac 96.86%);
  z-index: 1;
`

const StyledPlaatjie = styled(Plaatjie) <{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.3;
  mix-blend-mode: lighten;
  z-index: 2;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
  position: relative;
  z-index: 3;
`

const Block = styled.div`
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  border: 3px solid ${({ theme }) => theme.color.border.primaryLight};
  border-radius: 45px;
  padding: 40px 60px;
  position: relative;
  z-index: 2;

  & h2 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.dark};
    font-weight: ${({ theme }) => theme.font.weight.extraBold};
    font-size: 20px;
    line-height: 20px;
  }

  & h3 {
    font-size: 16px;
    color: ${({ theme }) => theme.color.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }

  @media screen and (max-width: 991px) {
    padding: 20px 30px;
  }

  @media screen and (max-width: 575px) {
    padding: 10px 30px;
    border-radius: 25px;
  }
`

const Vacancy = styled.div<{ darkmode: boolean }>`
  position: relative;

  & svg {
    margin-right: 10px;

    & path {
      ${({ darkmode, theme }) =>
    darkmode
      ? css`
              fill: ${theme.color.secondary};
            `
      : css`
              fill: ${theme.color.primaryLight};
            `}
    }

    & rect {
      ${({ darkmode, theme }) =>
    darkmode
      ? css`
              stroke: ${theme.color.secondary};
            `
      : css`
              stroke: ${theme.color.primaryLight};
            `}
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: ${({ theme }) => theme.color.greyDark};
    height: 1px;
    left: 0;
  }

  @media screen and (max-width: 350px) {
    & h2 {
      font-size: 16px;
    }

    & button {
      font-size: 12px;
      position: relative;
      left: -15px;
    }
  }
`

const TitleWrapper = styled.div`
  @media screen and (max-width: 350px) {
    flex-wrap: wrap-reverse;
  }
`

const SvgWrapper = styled.div`
  @media screen and (max-width: 575px) {
    width: 30px;
    display: flex;
    justify-content: flex-start;
  }
`

const BannerVacancies: React.FC<BannerVacanciesProps> = ({ fields }) => {
  const layout = useLayout()

  return (
    <StyledBannerVacancies id={fields.sectionid}>
      <BannerWrapper>
        <Gradient />
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <div className="container py-lg-5 pt-5">
          <Content content={fields.description || ''} className="pt-5 pt-lg-0" />
          <div className="row py-lg-5 pt-5 pb-3 justify-content-center">
            <div className="col-xl-8 col-lg-10 position-relative">
              <Block>
                {fields.vacancies?.map((v, index) => (
                  <Vacancy
                    darkmode={layout.darkMode}
                    className="py-4"
                    key={index}
                  >
                    <TitleWrapper className="d-flex justify-content-between align-items-center">
                      <h2>{v?.title}</h2>
                      <Button
                        arrow
                        className="secondary transparent hover-primaryLight"
                        to={v?.uri || '/'}
                      >
                        {layout.locale === 'nl_NL'
                          ? `Bekijk vacature`
                          : `See vacancy`}
                      </Button>
                    </TitleWrapper>
                    <div className="row pt-2">
                      <h3 className="col-sm-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Clock />
                        </SvgWrapper>
                        {v?.vacancypreview?.type}
                      </h3>
                      <h3 className="col-sm-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Pin />
                        </SvgWrapper>
                        {v?.vacancypreview?.location}
                      </h3>
                      <h3 className="col-sm-4 d-flex align-items-center">
                        <SvgWrapper>
                          <Bars />
                        </SvgWrapper>
                        {v?.vacancypreview?.niveau}
                      </h3>
                    </div>
                  </Vacancy>
                ))}
                <div className="d-flex justify-content-center mt-3 pt-md-5 pb-lg-3 pb-1">
                  <Button
                    arrow
                    className="filled secondary hover-secondary"
                    to={fields.link?.url || '/'}
                    target={fields.link?.target || '_blank'}
                  >
                    {fields.link?.title}
                  </Button>
                </div>
              </Block>
            </div>
          </div>
        </div>
      </BannerWrapper>
    </StyledBannerVacancies>
  )
}

export default BannerVacancies
